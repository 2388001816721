import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./rolelisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import { ReactSVG } from "react-svg";
import { toggleClass } from "../../../utils/commonUtils";
import useRolesAndPermissions from "../../../Hooks/useRolesAndPermissions";
import Loading from "../../../components/LoadingPage/Loading";
import CommonSearchOnly from "../../../components/commonSearchOnly/commonSearchOnly";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";

const RoleListing = () => {
  const { getRolesAPI, getRolesLoading, deleteRolesPermissionAPI,deleteRolesPermissionLoading} = useRolesAndPermissions();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [rolesList, setRolesList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const defaultFilter = { searchText: "" };
  const [currentRole, setCurrentRole] = useState<any>();
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const getRoles = () => {
    getRolesAPI(searchFieldVal)
      .then((res: any) => setRolesList(res))
      .catch();
  };
  useEffect(() => {
    getRoles();
  }, [searchFieldVal]);

  const deleteRole = async () => {
    await deleteRolesPermissionAPI({
      id: currentRole.id,
      deletedBy: currentUserId ?? "",
    });
    toggleModal();
    getRoles();
  };
  return (
    <div className="role-listing-wrapper">
      {getRolesLoading || deleteRolesPermissionLoading ? <Loading /> : null}

      <CommonSearchOnly
        title="Roles & Permissions"
        buttonTitle="Add Role"
        href="/add-role"
        disRecords={rolesList.length || 0}
        totalRecords={rolesList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Role ID</th>
              <th>Role Name</th>
              <th>DESCRIPTION</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rolesList?.map((x: any) => (
              <tr key={x.id}>
                <td>{x.id}</td>
                <td>{x.roleName}</td>
                <td>{x.roleDescription}</td>
                <td key={x.id}>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href={`/edit-role/${x.id}`}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item href="#"  onClick={() => {
                          setCurrentRole(x);
                          toggleModal();
                        }}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteRole}
        recordName={currentRole?.roleName || ""}
        modelName={`Role`}
      />
    </div>
  );
};

export default RoleListing;
