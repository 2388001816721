import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";
import { useState } from "react";

const LocationsList = () => {
  const [reportKey, setReportKey] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const onSelectLocationStatus = (e: any) => {
    setIsActive(e.target.value);
    setReportKey((prevKey) => prevKey + 1);
  };

  return (
    <div className="report-content-wrapper">
      <ReportHeader
        reportTitle="SRX Report - List of Locations"
        // reportDate="04 April 2024"
      />
      <div className="report-filter-wrapper">
        <div className="report-filter-item-wrapper">
          <div
            className="filter-item-box"
            style={{ width: "120px", marginTop: "10px" }}
          >
            <select className="form-select" onChange={onSelectLocationStatus}>
              <option value="true">Active</option>
              <option value="false">In Active</option>
            </select>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
        </div>
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-title">
            <h4></h4>
          </div>
          <div className="filter-item-detail-table-wrapper">
            <TelerikReporting
              key={reportKey}
              params={{isActive: isActive }}
              reportName="ListOfLocations.trdp"
              reportEndPoint="reports/listoflocations/get"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsList;
