import { useState } from "react";
import DatePicker from "react-date-picker";
import { MultiSelect } from "react-multi-select-component";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";
import moment from "moment";
const stockOptions = [
  { label: "Private", value: "private" },
  { label: "Federal", value: "federal" },
  { label: "Petients", value: "petients" },
];

const typeOptions = [
  { label: "Pediatric Vaccine (PDV)", value: "Pediatric Vaccine" },
  { label: "Vaccine (VAC)", value: "Vaccine" },
  { label: "Procedure (PROC)", value: "Procedure" },
  { label: "Injection (INJEC)", value: "Injection" },
  { label: "IT Equipment 1 (ITEQUIP)", value: "IT Equipment 1 (ITEQUIP)" },
  { label: "Covid 19 Test (COVID 19)", value: "Covid 19 Test (COVID 19)" },
];

const locationOptions = [
  {
    label: "1234 Maple Street Springfield, Anytown, USA",
    value: "1234 Maple Street Springfield, Anytown, USA",
  },
  {
    label: "5678 Oak Avenue Riverside, Smalltown, USA",
    value: "5678 Oak Avenue Riverside, Smalltown, USA",
  },
  {
    label: "910 Elm Street Lakeside, Anytown, USA",
    value: "910 Elm Street Lakeside, Anytown, USA",
  },
  {
    label: "246 Pine Drive Sunnyvale, Suburbia, USA",
    value: "246 Pine Drive Sunnyvale, Suburbia, USA",
  },
  {
    label: "789 Washington Boulevard Hillcrest, Middle America, USA",
    value: "789 Washington Boulevard Hillcrest, Middle America, USA",
  },
  {
    label: "910 Elm Street Lakeside, Anytown, USA",
    value: "910 Elm Street Lakeside, Anytown, USA",
  },
  {
    label: "246 Pine Drive Sunnyvale, Suburbia, USA",
    value: "246 Pine Drive Sunnyvale, Suburbia, USA",
  },
];

const overrideStrings = {
  search: "Search",
  selectAll: "All",
  selectSomeItems: "All",
};
const InventoryReceivingReport = () => {
  const [selectedStock, setSelectedStock] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedLoc, setSelectedLoc] = useState([]);
  const [sddate, setSdDate] = useState("");
  const [eddate, setEdDate] = useState("");
  const [reportKey, setReportKey] = useState(0);
  const onSelectDate = (date: any, type: string) => {
    if (type === "sd") {
      setSdDate(moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    } else {
      setEdDate(moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    }

    setReportKey((prevKey) => prevKey + 1);
  };
  const cleearAll = () => {
    setSdDate("");
    setEdDate("");
  };
  return (
    <>
      <div className="report-filter-wrapper" style={{ marginBottom: "20px" }}>
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box">
            <label className="form-label">Start Date</label>
            <DatePicker
              format="MM/dd/yyyy"
              openCalendarOnFocus={false}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              className="form-control"
              value={sddate}
              onChange={(date: any) => {
                onSelectDate(date, "sd");
              }}
              clearIcon={null}
              calendarIcon={<i className="bi bi-calendar"></i>}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">End Date</label>
            <DatePicker
              format="MM/dd/yyyy"
              openCalendarOnFocus={false}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              className="form-control"
              value={eddate}
              onChange={(date: any) => {
                onSelectDate(date, "ed");
              }}
              clearIcon={null}
              calendarIcon={<i className="bi bi-calendar"></i>}
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Type</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={typeOptions}
              value={selectedStock}
              onChange={setSelectedStock}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Stock</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={stockOptions}
              value={selectedType}
              onChange={setSelectedType}
              labelledBy="Select"
              disableSearch={true}
            />
          </div>
          <div className="filter-item-box location-filter-item">
            <label className="form-label">Location</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={locationOptions}
              value={selectedLoc}
              onChange={setSelectedLoc}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">User</label>
            <select className="form-select">
              <option selected>All</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="filter-item-box clear-all-box">
            <button
              type="button"
              className="clear-filter-btn"
              onClick={cleearAll}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
      <TelerikReporting
        key={reportKey}
        params={{
          startDate: sddate,
          endDate: eddate,
          rxTypeId: "VAC",
          stockId: "",
          locationId: "",
          userId: "",
          isExport: "true",
        }}
        reportName="ReceivingDetailsReport.trdp"
        reportEndPoint="reports/inventoryreceivingdetails/get"
      />
    </>
  );
};

export default InventoryReceivingReport;
