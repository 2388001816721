import srxlogowithtext from "../../assets/images/SRX-logo-with-text.svg";
import sflogo from "../../assets/images/SourceFuse-logo.png";
import "./reportheader.scss";
const ReportHeader = ({ reportTitle, reportDate }: any) => {
  return (
    <div className="report-content-header">
      <div className="report-header-content">
        <h5>{reportTitle}</h5>
        <p>{reportDate}</p>
      </div>
      {/* <div className="report-header-logo-wrapper">
        <div className="report-header-logo-box srx-logo-box">
          <img src={srxlogowithtext} alt="srx" />
        </div>
        <div className="report-header-logo-box sf-logo-box">
          <img src={sflogo} alt="sourcefuse" />
        </div>
      </div> */}
    </div>
  );
};

export default ReportHeader;
