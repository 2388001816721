import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";

const typeOptions = [
  { label: "Pediatric Vaccine (PDV)", value: "Pediatric Vaccine" },
  { label: "Vaccine (VAC)", value: "Vaccine" },
  { label: "Procedure (PROC)", value: "Procedure" },
  { label: "Injection (INJEC)", value: "Injection" },
  { label: "IT Equipment 1 (ITEQUIP)", value: "IT Equipment 1 (ITEQUIP)" },
  { label: "Covid 19 Test (COVID 19)", value: "Covid 19 Test (COVID 19)" },
];

const overrideStrings = {
  search: "Search",
  selectAll: "All",
  selectSomeItems: "All",
};
const MedicationsList = () => {
  const [selectedStock, setSelectedStock] = useState([]);
  const [reportKey, setReportKey] = useState(0);
  return (
    <>
      <div className="report-filter-wrapper" style={{ marginBottom: "20px" }}>
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Type</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={typeOptions}
              value={selectedStock}
              onChange={setSelectedStock}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Show</label>
            <select className="form-select">
              <option selected>All</option>
              <option value="1">Active</option>
              <option value="2">Active &amp; Recall</option>
              <option value="3">Active &amp; Expired</option>
              <option value="3">Inactive</option>
            </select>
          </div>
          <div className="filter-item-box clear-all-box">
            <button type="button" className="clear-filter-btn">
              Clear All
            </button>
          </div>
        </div>
        <div className="report-export-box">
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} /> UOM
            Export
          </button>
        </div>
      </div>
      <TelerikReporting
        key={reportKey}
        params={{
          rxTypeId: "",
          filter: 0,
        }}
        reportName="ListofMedicationsReport.trdp"
        reportEndPoint="reports/medicationslist/get"
      />
    </>
  );
};

export default MedicationsList;
