import React from "react";
//@ts-ignore
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { selectConfigData } from "../../redux/config/configSlice";
import { useAppSelector } from "../../redux/hooks";

const TelerikReporting = ({
  params,
  reportName,
  reportEndPoint,
}: {
  params: { [key: string]: any };
  reportName: string;
  reportEndPoint: string;
}) => {
  const configData = useAppSelector(selectConfigData);
  const token = localStorage.getItem("jwtToken") ?? null;

  const reportSource = {
    report: reportName, // name of the report file that you want to render
    parameters: {
      ReportUrl: `${configData?.appApiBaseUrl}/${reportEndPoint}`,
      AuthToken: `Bearer ${token}`,
      ...params,
    },
  };

  return (
    <TelerikReportViewer
      reportSource={reportSource}
      viewerContainerStyle={{ height: "100%", width: "100%" }}
      serviceUrl={`${configData?.appApiBaseUrl}/reports`}
      viewMode="PRINT_PREVIEW"
      scaleMode="FIT_PAGE_WIDTH"
      scale="1.0"
      enableAccessibility={false} // Disable accessibility if not needed
    />
  );
};

export default TelerikReporting;
