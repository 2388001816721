import { commonBaseApiSlice } from "../commonBaseApiSlice";
export interface TrList {
  trDateTime: string;
  userId: string;
  user: User;
  sessionRefNo: string;
  trType: string;
  ref1?: string;
  ref2?: string;
  ref3?: string;
  details?: string;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}

export interface User {
  spSRXID: string;
  userName: string;
  password: string;
  title: any;
  fName: string;
  lName: string;
  mi: any;
  lastUsedDateAsHCP: string;
  toalCountAsHCP: number;
  lastUsedDateAsUser: string;
  toalCountAsUser: number;
  forceChangePassword: boolean;
  notes: any;
  deaNumber: string;
  email: any;
  userValue: number;
  clinicalRoleId: string;
  clinicalRole: any;
  emrId: any;
  roleId: string;
  role: any;
  refreshToken: string;
  refreshTokenExpiryTime: string;
  profilePicture: any;
  deletedBy: any;
  deleted: boolean;
  deletedOnUtc: any;
  id: string;
  isActive: boolean;
  createdOnUtc: string;
  updatedOnUtc: any;
  createdBy: string;
  updatedBy: any;
}
export const reportsApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserslist: builder.mutation({
      query: () => ({
        url: "/reports/listofusers/get",
        method: "GET",
      }),
    }),
    trReportsDD: builder.mutation({
      query: () => ({
        url: "/trlogs/rendertrlogpage",
        method: "GET",
      }),
    }),
    trReports: builder.mutation({
      query: (data) => ({
        url: "/trlogs/get",
        method: "POST",
        body: data,
      }),
    }),
    inventoryStausExport: builder.mutation({
      query: (data)=>({
        url: "/reports/inventorystatus/export",
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const {
  useGetUserslistMutation,
  useTrReportsDDMutation,
  useTrReportsMutation,
  useInventoryStausExportMutation,
} = reportsApiSlice;
