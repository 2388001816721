import React,{ forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Select from "react-select";
import useLabelSetup from "../../Hooks/useLabelSetup";
import { LabelSetup } from "../../redux/labelSetup/labelSetupSlice";
import useInventory from "../../Hooks/useInventory";
import moment from "moment";
interface labelOption {
    value: string;
    label: string;
}
interface IPrintBarcodeDropdown {
    selectedLabel: labelOption;
    setSelectedLabel: (value:labelOption)=>void;
    ref:any

}
const PrintBarcodeLabelDropdown = forwardRef(
  ({ setSelectedLabel, selectedLabel }: IPrintBarcodeDropdown, ref) => {
    const [searchText, setSearchText] = useState<string>("");
    const [printLabelId, setPrintLabelId] = useState<LabelSetup[]>([]);
    const { getLabelSetupAPI } = useLabelSetup();
    const { barcodePrintAPI } = useInventory();

    const getBarcodeLabels = () => {
      getLabelSetupAPI({ searchText }).then((res) => {
        const options = res?.map((option: any) => ({
          value: option.id,
          label: option.labelName,
        }));
        setPrintLabelId(options);

        // If options are returned, set the first one as the default selected label
        if (options?.length > 0) {
          setSelectedLabel(options[0]);
        }
      });
    };
    const getBarcodeVales =(selectedBarcodeItem:any)=>{
        const formattedDate = moment(selectedBarcodeItem?.expiryDate).format("MM/DD/YYYY");
        const clientID = localStorage.getItem("profileClientId");
        return {
        "Barcodes": [
          {
            "Key": "imageid1",
            "Value": `${selectedBarcodeItem?.rxSRXId}` //"0100300064827019173012251020240828-1DC5BD371"
          },
          {
            "Key": "imageid2",
            "Value": `${selectedBarcodeItem?.rxSRXId}` // "0100300064827019173012251020240828-1DC5BD371"
          },
          {
            "Key": "imageNdc",
            "Value": selectedBarcodeItem?.ndcCode //"0006-4827-01"
          },
          {
            "Key": "imageLot",
            "Value": selectedBarcodeItem?.lotNo //"20240828-1DC5BD371"
          },
          {
            "Key": "imageExpDate",
            "Value": formattedDate //"12/30/2025"
          },
          {
            "Key": "imageLotExpDate",
            "Value": `${selectedBarcodeItem?.lotNo}\t\t${formattedDate}` //"20240828-1DC5BD371\t\t12/30/2025"
          }
        ],
        "Data": [
          {
            "Key": "ClientId",
            "Value": clientID  //"STAGE"
          },
          {
            "Key": "Source",
            "Value": selectedBarcodeItem?.stock?.stockCode //"340B"
          },
          {
            "Key": "LotNo",
            "Value": selectedBarcodeItem?.lotNo //"20240828-1DC5BD371"
          },
          {
            "Key": "RxName",
            "Value": selectedBarcodeItem?.rxName //"01A VARIVAX"
          },
          {
            "Key": "Manufacturer",
            "Value": selectedBarcodeItem?.manufacturer //"Merck Sharp & Dohme Corp."
          },
          {
            "Key": "UserDef1",
            "Value": "-1"
          },
          {
            "Key": "ExpDate",
            "Value": formattedDate //"12/30/2025"
          },
          {
            "Key": "NDC10",
            "Value": selectedBarcodeItem?.ndcCode //"0006-4827-01"
          },
          {
            "Key": "Barcode",
            "Value": selectedBarcodeItem?.rxSRXId //"0100300064827019173012251020240828-1DC5BD371"
          },
          {
            "Key": "Row1Text",
            "Value":  `${selectedBarcodeItem?.rxName}-${selectedBarcodeItem?.manufacturer}-${selectedBarcodeItem?.stock?.stockCode}`//"01A VARIVAX-Merck Sharp & Dohme -340B"
          },
          {
            "Key": "Row2Text",
            "Value":  `${selectedBarcodeItem?.ndcCode}-${selectedBarcodeItem?.lotNo}-${formattedDate}` //"0006-4827-01-20240828-1DC5BD371-12/30/2025"
          },
          {
            "Key": "Row3Text",
            "Value": `${selectedBarcodeItem?.rxSRXId}-${clientID}`//"0100300064827019173012251020240828-1DC5BD371-STAGE"
          },
          {
            "Key": "LotExpDate",
            "Value": `${selectedBarcodeItem?.lotNo}\t\t${formattedDate}` //"20240828-1DC5BD371\t\t12/30/2025"
          },
          {
            "Key": "PatientName",
            "Value": "Test Trinet"
          },
          {
            "Key": "ProviderName",
            "Value": "Chris Buckley"
          },
          {
            "Key": "DepartmentName",
            "Value": "7 Hills Department (150)"
          },
          {
            "Key": "PrintDate",
            "Value": moment(new Date()).format("DD/MM/YYYY") //"11/22/2024"
          }
        ]
      }
    }

    const printBarcode = (lableId:string,selectedItem:any) => {
      const barcodeVales = getBarcodeVales(selectedItem);
      barcodePrintAPI({
          "lableId": lableId,
          "barcodeVales": barcodeVales
      }).then((res: any) => {
        window.open(res, "_blank", "noopener,noreferrer");
      });
    };

    useImperativeHandle(ref, () => ({
      printBarcode
    }));

    useEffect(() => {
      getBarcodeLabels();
    }, []);

    return (
      <div className="barcodes-list-wrapper invt-overview-info-box">
        {/* <label>Select Label</label> */}
        <Select
          classNamePrefix="react-select"
          options={printLabelId}
          id="locationId"
          name="locationId"
          value={printLabelId?.find(
            (option: any) => selectedLabel?.value === option.value
          )}
          onChange={(option: any) => {
            setSelectedLabel(option);
          }}
          className="react-select-container"
        />
      </div>
    );
  }
);

export default PrintBarcodeLabelDropdown;