import CustomModal from "../../../../../components/customModal/CustomModal";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useEffect, useState } from "react";
import useUnits from "../../../../../Hooks/useUnits";
import { DropDownValues } from "../../../../../utils/types";

export const InventoryRepackModel = ({
  locationDropDownVal,
  selectedItem,
  selectCurrentUserId,
  toggleInventoryrepackModal,
  invTransRepack,
  activeLocation,
}: any) => {
  const { getUnitsAPI } = useUnits();
  const [unitsList, setUnitsList] = useState<DropDownValues[]>([]);
  const getUnits = () => {
    getUnitsAPI({ searchText: "", includeDeleted: false })
      .then((res: any) =>
        setUnitsList(
          res.map((option: any) => {
            return {
              value: option.id,
              label: option.unitName,
            };
          }),
        ),
      )
      .catch();
  };
  useEffect(() => {
    getUnits();
  }, []);
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      targetUnitId: selectedItem.unitId,
      targetQuantity: "",
      repackageQuantityPerUnit: "",
      locationId: activeLocation ?? "",
      itemId: selectedItem.id,
      userId: selectCurrentUserId,
    },
    validationSchema: Yup.object({
      targetQuantity: Yup.number()
        .min(0, "Target Quantity must be a positive number")
        .required("Target Quantity is Required"),
      locationId: Yup.string().required("Location is Required"),
      repackageQuantityPerUnit: Yup.number()
        .min(0, "Quantity must be a positive number")
        .required("Quantity is Required"),
      targetUnitId: Yup.string().required("Target Unit is Required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        invTransRepack(values);
      } catch (err: any) {}
      setSubmitting(false);
    },
  });

  return (
    <CustomModal
      title="Repack Inventory"
      content={
        <div className="modal--content--wrapper">
          <div className="modal--inputbox">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4">
                <Form.Label>Select Location</Form.Label>
                <Select
                  placeholder="Select Location"
                  classNamePrefix="react-select"
                  options={locationDropDownVal}
                  id="locationId"
                  name="locationId"
                  value={locationDropDownVal?.find(
                    (option: any) => option.value === values.locationId,
                  )}
                  onChange={(option: any) =>
                    handleChange("locationId")(option.value)
                  }
                  className={`${
                    touched.locationId && !!errors.locationId
                      ? "is-invalid-border"
                      : ""
                  } react-select-container `}
                />
                {touched.locationId && errors.locationId && (
                  <Form.Control.Feedback type="invalid">
                    {errors.locationId as string}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Target Unit</Form.Label>

                <Select
                  placeholder="Select Unit"
                  classNamePrefix="react-select"
                  options={unitsList}
                  id="targetUnitId"
                  name="targetUnitId"
                  value={unitsList?.find(
                    (option: any) => option.value === values.targetUnitId,
                  )}
                  onChange={(option: any) =>
                    handleChange("targetUnitId")(option.value)
                  }
                  className={`${
                    touched.targetUnitId && !!errors.targetUnitId
                      ? "is-invalid-border"
                      : ""
                  } react-select-container `}
                />
                {touched.targetUnitId && errors.targetUnitId && (
                  <Form.Control.Feedback type="invalid">
                    {errors.targetUnitId as string}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>{`Target Quantity (${
                  unitsList?.find(
                    (option: any) => option.value === values.targetUnitId,
                  )?.label ?? "select"
                })`}</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  step="any"
                  placeholder="Enter Quantity"
                  name="targetQuantity"
                  id="targetQuantity"
                  onChange={handleChange}
                  value={values.targetQuantity}
                  isInvalid={touched.targetQuantity && !!errors.targetQuantity}
                />
                {touched.targetQuantity && errors.targetQuantity ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.targetQuantity}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>
                  {selectedItem.unit.unitName} Per{" "}
                  {unitsList?.find(
                    (option: any) => option.value === values.targetUnitId,
                  )?.label || "select"}{" "}
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={handleChange}
                  id="repackageQuantityPerUnit"
                  name="repackageQuantityPerUnit"
                  placeholder="Enter Quantity"
                  isInvalid={
                    touched.repackageQuantityPerUnit &&
                    !!errors.repackageQuantityPerUnit
                  }
                />
                {touched.repackageQuantityPerUnit &&
                errors.repackageQuantityPerUnit ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.repackageQuantityPerUnit}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="full-width text-end">
                <Button
                  onClick={toggleInventoryrepackModal}
                  style={{ marginRight: "10px" }}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Repack
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      }
      onClose={toggleInventoryrepackModal}
    />
  );
};
