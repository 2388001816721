import "./labeldetails.scss";
import LabelMasterProfileHeader from "../../../components/LabelMasterProfileHeader/LabelMasterProfileHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useLabelSetup from "../../../Hooks/useLabelSetup";
import { LabelSetup } from "../../../redux/labelSetup/labelSetupSlice";
import Loading from "../../../components/LoadingPage/Loading";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import { toggleClass } from "../../../utils/commonUtils";
import { DuplicateItemModel } from "../LabelListing/popUpModels/duplicateItemModel";
const LabelDetails = () => {
  const { id } = useParams();
  const [labelSetupDetails, setLabelSetupDetails] = useState<LabelSetup>();
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const { getLabelSetupByIdAPI, getLabelSetupByIdLoading } = useLabelSetup();
  const {
    deleteLabelSetupAPI,
    deleteLabelSetupLoading,
    duplicateLabelSetupAPI,
    duplicateLabelSetupLoading,
  } = useLabelSetup();

  const currentUserId = useAppSelector(selectCurrentUserId);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    getLabelSetupByIdAPI(id ?? "").then((res: LabelSetup) => {
      if (res) {
        setLabelSetupDetails(res);
      }
    });
  }, []);
  const deleteLabelSetup = async () => {
    await deleteLabelSetupAPI({
      id: labelSetupDetails?.id,
      deletedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/label-setup");
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  const handleDuplicate = async () => {
    await duplicateLabelSetupAPI({
      id: labelSetupDetails?.id,
      updatedBy: currentUserId ?? "",
    });
    navigate("/label-setup");
  };
  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };
  return (
    <div className="profile-page-wrapper">
      {getLabelSetupByIdLoading ||
      deleteLabelSetupLoading ||
      duplicateLabelSetupLoading ? (
        <Loading />
      ) : null}
      <LabelMasterProfileHeader
        title={labelSetupDetails?.labelName ?? "-"}
        id={id}
        toggleModal={toggleModal}
        duplicateType={toggleDuplicateModal}
      />
      <div className="profile-detail-box-wrapper label-details">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Label Name</label>
            <p>{labelSetupDetails?.labelName}</p>
          </div>
          <div className="profile-info-box">
            <label>Label Type</label>
            <p>{labelSetupDetails?.labelTypeName?.labelTypeName}</p>
          </div>
          <div className="profile-info-box">
            <label>Sort Order</label>
            <p>{labelSetupDetails?.sortOrder}</p>
          </div>
          <div className="profile-info-box">
            <label>Active</label>
            <p>{labelSetupDetails?.isActive ? "Yes" : "No"}</p>
          </div>
          <div className="profile-info-box full-width">
            <label>Template HTML</label>
            <textarea
              value={labelSetupDetails?.templateHTML || ""}
              disabled={true}
              rows={3}
              style={{
                width: "100%",
                resize: "vertical",
                backgroundColor: "white",
                fontSize: "14px",
                color: "black",
              }}
            />
          </div>
          <div className="profile-info-box full-width">
            <label>Label JSON</label>
            <textarea
              value={labelSetupDetails?.labelJSON || ""}
              disabled={true}
              rows={3}
              style={{
                width: "100%",
                resize: "vertical",
                backgroundColor: "white",
                fontSize: "14px",
                color: "black",
              }}
            />
          </div>
        </div>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteLabelSetup}
        recordName={labelSetupDetails?.labelName || ""}
        modelName={`item`}
      />
      <DuplicateItemModel
        selectedItem={labelSetupDetails}
        toggleDuplicateModal={toggleDuplicateModal}
        showModal={showDuplicateModal}
        handleDuplicate={handleDuplicate}
      />
    </div>
  );
};

export default LabelDetails;
