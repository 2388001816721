import "./predictions.scss";
import Plot from "react-plotly.js";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import React from "react";
import useAdmin from "../../Hooks/useAdmin";
import Loading from "../../components/LoadingPage/Loading";
const Predictions = () => {
  const [predictionsData, setPredictionsData] = useState<any>([]);
  const { getPredictionsAPI, getPredictionsLoading } = useAdmin();
  useEffect(() => {
    getPredictionsAPI({}).then((res) => {
      setPredictionsData(res);
    });
  }, []);
  return (
    <div className="prediction-graph-wrapper">
      {getPredictionsLoading ? <Loading /> : null}
      <div className="prediction-header-box">
        <h2>
          The Graphs below Represent Trend and Forecast for each Drug Category
          based on Historical usage.
        </h2>
        <h5>
          The Forecasts are Generated by Training an AI (forecasting) Model
          using Historical Data
        </h5>
      </div>
      <Row>
        {predictionsData?.map((data: any) => (
          <Col key={JSON.parse(data.drugDetails).name}>
            <div className="graph">
              <h3>{JSON.parse(data.drugDetails).name.split("_")[0]}</h3>
              <Plot
                data={JSON.parse(data.drugDetails).forecast_plot.data}
                layout={JSON.parse(data.drugDetails).forecast_plot.layout}
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};
export default Predictions;
