import { getFilters } from "../../utils/commonUtils";
import { commonBaseApiSlice } from "./../commonBaseApiSlice";

export type GetBarcode = {
  useMfrBarcode: true;
  checkInternet: true;
  addNdcProductMaster: true;
  isMedicine: true;
  barcode: "string";
};

export const inventoryApiSlice = commonBaseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllItems: builder.mutation({
      query: (data) => ({
        url: `/item/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    getItemCreateDropdownVal: builder.mutation({
      query: () => ({
        url: "/item/renderitempage",
        method: "GET",
      }),
    }),
    getBarcodeDetails: builder.mutation({
      query: (data: GetBarcode) => ({
        url: "/item/decodebarcode",
        method: "POST",
        body: data,
      }),
    }),
    createInventortItem: builder.mutation({
      query: (data: any) => ({
        url: "/item/create",
        method: "POST",
        body: data,
      }),
    }),
    getInventoryCountList: builder.mutation({
      query: (data: any) => ({
        url: `/inventorycount/getinventorycountlist?itemId=${data}`,
        method: "GET",
      }),
    }),

    inventoryTransactionsReceive: builder.mutation({
      query: (data: any) => ({
        url: `inventorytransaction/receive`,
        method: "POST",
        body: data,
      }),
    }),
    inventoryTransactionsCount: builder.mutation({
      query: (data: any) => ({
        url: `inventorytransaction/count`,
        method: "POST",
        body: data,
      }),
    }),
    inventoryTransactionsRepack: builder.mutation({
      query: (data: any) => ({
        url: `inventorytransaction/repack`,
        method: "POST",
        body: data,
      }),
    }),
    inventoryTransactionsAdjust: builder.mutation({
      query: (data: any) => ({
        url: `/inventorytransaction/adjust`,
        method: "POST",
        body: data,
      }),
    }),
    inventoryTransactionsTransfer: builder.mutation({
      query: (data: any) => ({
        url: `/inventorytransaction/transfer`,
        method: "POST",
        body: data,
      }),
    }),
    inventoryOrderLevel: builder.mutation({
      query: (itemId: any) => ({
        url: `/inventoryreorderlevel/get?itemId=${itemId}`,
        method: "GET",
      }),
    }),
    inventoryOrderLevelEdit: builder.mutation({
      query: (data: any) => ({
        url: `/inventoryreorderlevel/edit`,
        method: "PUT",
        body: data,
      }),
    }),
    inventoryOrderLevelDelete: builder.mutation({
      query: (id: any) => ({
        url: `/inventoryreorderlevel/delete?id=${id}`,
        method: "Delete",
      }),
    }),
    invOrderManagement: builder.mutation({
      query: (itemId: string) => ({
        url: `/ordermanagement/get?RxSRXId=${itemId}`,
        method: "GET",
      }),
    }),
    invOrdManGetQtyOnHand: builder.mutation({
      query: (data) => ({
        url: `/ordermanagement/getqtyonhand?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    inventoryTransaction: builder.mutation({
      query: () => ({
        url: "/transaction/get",
        method: "GET",
      }),
    }),
    editInventoryItem: builder.mutation({
      query: (data) => ({
        url: "/item/edit",
        method: "PUT",
        body: data,
      }),
    }),
    duplicateItem: builder.mutation({
      query: (data) => ({
        url: `/item/duplicate?${getFilters(data)}`,
        method: "POST",
        body: data,
      }),
    }),
    getOrderUnitItems: builder.mutation({
      query: (data) => ({
        url: `/orderunit/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    getBarcodes: builder.mutation({
      query: (itemId) => ({
        url: `/rxextensionmultiplebarcode/getbyid?id=${itemId}`,
        method: "GET",
      }),
    }),
    barcodeCreate: builder.mutation({
      query: (data) => ({
        url: `/rxextensionmultiplebarcode/create`,
        method: "POST",
        body: data,
      }),
    }),
    barcodeEdit: builder.mutation({
      query: (data) => ({
        url: `/rxextensionmultiplebarcode/edit`,
        method: "POST",
        body: data,
      }),
    }),
    barcodeDefault: builder.mutation({
      query: (data) => ({
        url: `/rxextensionmultiplebarcode/Default?${getFilters(data)}`,
        method: "PUT",
      }),
    }),
    createOrderUnitItems: builder.mutation({
      query: (data) => ({
        url: `/orderunit/create`,
        method: "POST",
        body: data,
      }),
    }),
    editOrderUnitItems: builder.mutation({
      query: (data) => ({
        url: `/orderunit/edit`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteOrderUnitItems: builder.mutation({
      query: (data) => ({
        url: `/orderunit/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
    deleteInventoryItem: builder.mutation({
      query: (data) => ({
        url: `/item/delete?${getFilters(data)}`,
        method: "DELETE",
      }),
    }),
    getOrderingProducts: builder.mutation({
      query: (data) => ({
        url: `/orderingproductmaster/get?${getFilters(data)}`,
        method: "GET",
      }),
    }),
    createOrderingProducts: builder.mutation({
      query: (data: any) => ({
        url: `/orderingproductmaster/create`,
        method: "POST",
        body: data,
      }),
    }),
    getNewBarcode: builder.mutation({
      query: () => ({
        url: `/item/getbarcodes`,
        method: "GET",
      }),
    }),
    barcodePrint: builder.mutation({
      query: (data) => ({
        url: `/rxextensionmultiplebarcode/print?lableId=${data.lableId}`,
        method: "POST",
        body: data.barcodeVales
      }),
    }),
    invTransactions: builder.mutation({
      query: (itemId: string) => ({
        url: `/reports/inventorytransaction/get`,
        method: "POST",
        body: { rxSRXId: itemId },
      }),
    }),
  }),
});

export const {
  useInvTransactionsMutation,
  useBarcodePrintMutation,
  useGetAllItemsMutation,
  useGetItemCreateDropdownValMutation,
  useGetBarcodeDetailsMutation,
  useCreateInventortItemMutation,
  useGetInventoryCountListMutation,
  useInventoryTransactionsReceiveMutation,
  useInventoryTransactionsAdjustMutation,
  useInventoryTransactionsTransferMutation,
  useInventoryTransactionsCountMutation,
  useInventoryOrderLevelEditMutation,
  useInventoryTransactionMutation,
  useEditInventoryItemMutation,
  useDeleteInventoryItemMutation,
  useGetOrderingProductsMutation,
  useCreateOrderingProductsMutation,
  useGetNewBarcodeMutation,
  useDuplicateItemMutation,
  useGetOrderUnitItemsMutation,
  useCreateOrderUnitItemsMutation,
  useEditOrderUnitItemsMutation,
  useDeleteOrderUnitItemsMutation,
  useInvOrderManagementMutation,
  useInvOrdManGetQtyOnHandMutation,
  useInventoryOrderLevelDeleteMutation,
  useInventoryTransactionsRepackMutation,
  useGetBarcodesMutation,
  useBarcodeCreateMutation,
  useBarcodeEditMutation,
  useBarcodeDefaultMutation,
} = inventoryApiSlice;
