import CustomModal from "../customModal/CustomModal";
import duplicateitemmodalimge from "../../assets/images/duplicate-item-modal-image.svg";
import { Button } from "react-bootstrap";
export const CommonDuplicateModel = ({
  showDuplicateModal,
  modelName,
  toggleDuplicateModal,
  recordName,
  duplicate,
}: any) => {
  return (
    <>
      {showDuplicateModal && (
        <CustomModal
          title=""
          content={
            <>
              <div className="modal--content--wrapper">
                <img src={duplicateitemmodalimge} alt="" />
                <h5>
                  Are you sure you want to <br />
                  duplicate this {modelName} '{recordName}'?
                </h5>
              </div>
              <div className="modal--action--btn--wrapper">
                <Button
                  type="button"
                  variant="outline-primary"
                  onClick={toggleDuplicateModal}
                >
                  Cancel
                </Button>
                <Button type="button" variant="primary" onClick={duplicate}>
                  Duplicate
                </Button>
              </div>
            </>
          }
          onClose={toggleDuplicateModal}
        />
      )}
    </>
  );
};
