import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import "./stocklisting.scss";
import threedotvertical from "../../../assets/images/three-dots-vertical.svg";
import editicon from "../../../assets/images/edit-icon.svg";
import viewicon from "../../../assets/images/view-icon.svg";
import deactivateicon from "../../../assets/images/deactivate-icon.svg";
import deleteicon from "../../../assets/images/delete-icon.svg";
import { ReactSVG } from "react-svg";
import useStocks from "../../../Hooks/useStocks";
import Loading from "../../../components/LoadingPage/Loading";
import { setSelectedStock } from "../../../redux/stock/stockSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { toggleClass } from "../../../utils/commonUtils";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import ItemListingTitle from "../../../components/itemListingTitle/itemListingTitle";

const StockListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const { getStocksAPI, getStocksLoading } = useStocks();
  const { activeStockAPI, setStockStatusActiveLoading } = useStocks();
  const { deactiveStockAPI, setStockStatusDeActiveLoading } = useStocks();
  const { deleteStockAPI, deleteStockLoading } = useStocks();
  const [showModal, setShowModal] = useState(false);
  const [stocksList, setStocksList] = useState<any>([]);
  const [currentStock, setCurrentStock] = useState<any>();
  const defaultFilter = { searchText: "", active: true };
  const [searchFieldVal, setSearchFieldVal] = useState(defaultFilter);
  const deleteStock = async () => {
    await deleteStockAPI({
      id: currentStock.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    getStocks();
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };

  const getStocks = () => {
    getStocksAPI(searchFieldVal)
      .then((res: any) => setStocksList(res))
      .catch();
  };
  const editStock = (stock: any) => {
    dispatch(setSelectedStock(stock));
    navigate("/edit-stock");
  };
  const navigateToView = (stock: any) => {
    dispatch(setSelectedStock(stock));
    navigate("/view-stock");
  };
  const updateStatus = async (stock: any) => {
    if (stock.isActive) {
      await deactiveStockAPI({ id: stock.id, updatedBy: currentUserId ?? "" });
    } else {
      await activeStockAPI({ id: stock.id, updatedBy: currentUserId ?? "" });
    }
    getStocks();
  };
  useEffect(() => {
    getStocks();
  }, [searchFieldVal]);
  return (
    <div className="stock-listing-wrapper">
      {getStocksLoading ||
      setStockStatusActiveLoading ||
      setStockStatusDeActiveLoading ||
      deleteStockLoading ? (
        <Loading />
      ) : null}

      <ItemListingTitle
        title="Stock Master"
        buttonTitle="Add New"
        href="/add-stock"
        disRecords={stocksList.length || 0}
        totalRecords={stocksList.length || 0}
        searchVal={searchFieldVal}
        setSearchVal={setSearchFieldVal}
        defaultFilter={defaultFilter}
        isAddPermitted={true}
      />
      <div className="listing-table-wrapper">
        <Table responsive="md" hover={true}>
          <thead>
            <tr>
              <th>Stock Code</th>
              <th>Stock Name</th>
              <th>Sort Order</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {stocksList?.map((x: any) => (
              <tr
                key={x.id}
                onDoubleClick={() => {
                  navigateToView(x);
                }}
              >
                <td>{x.stockCode}</td>
                <td>{x.stockName}</td>
                <td>{x.sortOrder}</td>
                <td>
                  <span
                    className={`status-circle ${x.isActive ? "active" : "inactive"}`}
                  >
                    {x.isActive ? "Active" : "InActive"}
                  </span>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <ReactSVG
                        className="svg-box"
                        wrapper="span"
                        src={threedotvertical}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigateToView(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={viewicon}
                        />
                        View
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          updateStatus(x);
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deactivateicon}
                        />
                        {x.isActive ? "Deactivate" : "Activate"}
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => editStock(x)}>
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={editicon}
                        />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrentStock(x);
                          toggleModal();
                        }}
                      >
                        <ReactSVG
                          className="svg-box"
                          wrapper="span"
                          src={deleteicon}
                        />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteStock}
        recordName={currentStock?.stockName || ""}
        modelName={`stock`}
      />
    </div>
  );
};

export default StockListing;
