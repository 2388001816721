import "./usagetransactions.scss";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Dropdown, Table } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import threedotvertical from "../../assets/images/three-dots-vertical.svg";
import viewicon from "../../assets/images/view-icon.svg";
import deleteicon from "../../assets/images/delete-icon.svg";
import { toggleClass } from "../../utils/commonUtils";
import moment from "moment";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useUsageTransactions from "../../Hooks/useUsageTransactions";
import { UsageTransList } from "../../redux/usageTransactions/usageTransactionsApiSlice";
import { selectCurrentUserId } from "../../redux/auth/authSlice";
import { useAppSelector } from "../../redux/hooks";

import Loading from "../../components/LoadingPage/Loading";
import { useNavigate } from "react-router-dom";
import { DeleteConfModel } from "./popUp/deleteConfModel";

const UsageTransactions = () => {
  const { getUsageTransListAPI, usageTransDeleteAPI, usageTransDeleteLoading } =
    useUsageTransactions();
  const navigate = useNavigate();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<UsageTransList>();
  const [usageTransList, setUsageTransList] = useState<UsageTransList[]>([]);
  const initialForm = {
    searchText: "",
    endDate: "",
    startDate: "",
    DOB: "",
    isExcluded: null,
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  useEffect(() => {
    getList();
  }, []);
  const getList = () => {
    getUsageTransListAPI(values).then((res) => {
      setUsageTransList(res);
    });
  };
  const { handleChange, values, handleSubmit, resetForm, setFieldValue } =
    useFormik({
      initialValues: initialForm,
      validationSchema: Yup.object({}),

      onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        try {
          getList();
        } catch (err: any) {}
        setSubmitting(false);
      },
      enableReinitialize: true,
    });
  const deleteRecord = () => {
    usageTransDeleteAPI({
      id: selectedItem?.id,
      deletedBy: currentUserId,
    }).then((res) => {
      toggleModal();
      getList();
    });
  };
  return (
    <div className="usage-trans-content-wrapper">
      {usageTransDeleteLoading ? <Loading /> : null}
      <div className="api-log-header">
        <h2>Usage Transactions</h2>
      </div>
      <div className="report-filter-wrapper">
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box">
            <label className="form-label">Search</label>
            <input
              type="text"
              min={0}
              className="form-control"
              placeholder="Search"
              id="searchText"
              onChange={(e: any) => {
                handleChange("searchText")(e.target.value);

                handleSubmit();
              }}
              value={values.searchText}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">DOB</label>
            <div className="calender-box">
              <DatePicker
                format="MM/dd/yyyy"
                openCalendarOnFocus={false}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                className="form-control"
                maxDate={new Date()}
                clearIcon={null}
                calendarIcon={<i className="bi bi-calendar"></i>}
                value={values.DOB}
                showLeadingZeros={true}
                onChange={(date: any) => {
                  handleChange({
                    target: {
                      name: "DOB",
                      value: moment(date).isValid()
                        ? moment(date).format("L")
                        : "",
                    },
                  });
                  handleSubmit();
                }}
              />
            </div>
          </div>
          <div className="filter-item-box">
            <label className="form-label">Start Date</label>
            <div className="calender-box">
              <DatePicker
                format="MM/dd/yyyy"
                openCalendarOnFocus={false}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                className="form-control"
                clearIcon={null}
                maxDate={values.endDate ? new Date(values.endDate) : new Date()}
                calendarIcon={<i className="bi bi-calendar"></i>}
                value={values.startDate}
                showLeadingZeros={true}
                onChange={(date: any) => {
                  handleChange({
                    target: {
                      name: "startDate",
                      value: moment(date).isValid()
                        ? moment(date).format("L")
                        : "",
                    },
                  });
                  handleSubmit();
                }}
              />
            </div>
          </div>
          <div className="filter-item-box">
            <label className="form-label">End Date</label>
            <div className="calender-box">
              <DatePicker
                format="MM/dd/yyyy"
                openCalendarOnFocus={false}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                className="form-control"
                clearIcon={null}
                minDate={
                  values.startDate ? new Date(values.startDate) : new Date()
                }
                calendarIcon={<i className="bi bi-calendar"></i>}
                value={values.endDate}
                showLeadingZeros={true}
                onChange={(date: any) => {
                  handleChange({
                    target: {
                      name: "endDate",
                      value: moment(date).isValid()
                        ? moment(date).format("L")
                        : "",
                    },
                  });
                  handleSubmit();
                }}
              />
            </div>
          </div>
          <div className="filter-item-box filter-checkbox-items">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                checked={values.isExcluded ?? false}
                onChange={(e) => {
                  setFieldValue("isExcluded", e.target.checked);
                  handleSubmit();
                }}
              />
              <label className="form-check-label">Excluded</label>
            </div>
          </div>
          <div className="filter-item-box clear-all-box">
            <button
              type="button"
              className="clear-filter-btn"
              onClick={() => {
                resetForm();
                handleSubmit();
              }}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
      <div className="filter-detail-wrapper">
        <div
          className="filter-item-detail-wrapper"
          style={{ marginTop: "14px" }}
        >
          <span style={{ fontSize: "12px", color: "#A6A6A6" }}>
            Displaying {usageTransList?.length || 0} of{" "}
            {usageTransList?.length || 0} Results
          </span>
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>Patient ID</th>
                  <th>Name</th>
                  <th>DOB</th>
                  <th>Gender</th>
                  <th>LOCATION ID</th>
                  <th>Rx Name</th>
                  <th>Shot Date</th>
                  <th>Deleted</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {usageTransList.map((item) => (
                  <tr
                    key={item.id}
                    onDoubleClick={() =>
                      navigate(`/usage-transactions-profile/${item.id}`)
                    }
                  >
                    <td>{item.patientId}</td>
                    <td>
                      {item.patient.firstName} {item.patient.lastName}
                    </td>
                    <td>
                      {item?.patient?.dob
                        ? moment(item.patient.dob).format("L")
                        : "-"}
                    </td>
                    <td>{item?.patient.gender}</td>
                    <td>{item.location.locationName}</td>
                    <td>{item.item.rxName}</td>
                    <td>
                      {item?.shotDate
                        ? moment(item.shotDate).format("MM/DD/YYYY HH:mm:ss")
                        : "-"}
                    </td>
                    <td>
                      <span
                        className={`status-tag ${item.deleted ? "active" : "inactive"}`}
                      >
                        {item.deleted ? "Yes" : "No"}
                      </span>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                          <ReactSVG
                            className="svg-box"
                            wrapper="span"
                            src={threedotvertical}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            href={`/usage-transactions-profile/${item.id}`}
                          >
                            <ReactSVG
                              className="svg-box"
                              wrapper="span"
                              src={viewicon}
                            />
                            View
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedItem(item);
                              toggleModal();
                            }}
                            className={item.deleted ? "disabled" : ""}
                            disabled={item.deleted}
                          >
                            <ReactSVG
                              className="svg-box"
                              wrapper="span"
                              src={deleteicon}
                            />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteRecord}
        recordName={
          (selectedItem?.patient.firstName ?? "") +
          " " +
          (selectedItem?.patient.lastName ?? "")
        }
        modelName={`record`}
      />
    </div>
  );
};

export default UsageTransactions;
