import "./trreport.scss";
import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Form, Table } from "react-bootstrap";
import CustomModal from "../../components/customModal/CustomModal";
import Select from "react-select";
import useReports from "../../Hooks/useReports";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Loading from "../../components/LoadingPage/Loading";
import { TrList } from "../../redux/reports/reportsApiSlice";
import { DropDownValues } from "../../utils/types";
import { useAppSelector } from "../../redux/hooks";
import { selectCurrentUserId } from "../../redux/auth/authSlice";
const TRReport = () => {
  const [trTypeOptions, setTrTypeOptions] = useState<any>([]);
  const [userOptions, setUserOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [trList, setTrList] = useState<TrList[]>([]);
  const currentUser = useAppSelector(selectCurrentUserId);
  const { trReportsDDAPI, trReportsDDLoading, trReportsAPI, trReportsLoading } =
    useReports();

  const DynamicInitialValues = {
    userId: "00000000-0000-0000-0000-000000000000",
    barcode: "",
    type: "",
    fromDate: moment().subtract(7, "days").toISOString(),
    toDate: moment().toISOString(),
    rowCount: 10,
  };
  const [formInitialValues, setFormInitialValues] =
    useState(DynamicInitialValues);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  useEffect(() => {
    trReportsDDAPI().then((res) => {
      setUserOptions(
        res.users?.map((option: { value: any; key: any }) => {
          return { label: option.value, value: option.key };
        }),
      );
      setTrTypeOptions([
        ...res.types?.map((option: { value: any; key: any }) => {
          return { label: option.value, value: option.key };
        }),
      ]);
      setFormInitialValues({
        ...formInitialValues,
        userId:
          res?.users?.find(
            (option: { value: any; key: any }) => option.value === "All",
          )?.key ?? currentUser,
      });
    });
    handleSubmit();
  }, []);

  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      rowCount: Yup.number()
        .integer("Enter a positive number")
        .positive("Enter a positive number")
        .required("Enter a positive number"),
      toDate: Yup.string().required("To Date is Required"),
      fromDate: Yup.string().required("From Date is Required"),
    }),
    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      if (values.rowCount) {
        try {
          trReportsAPI(values).then((res) => {
            setTrList(res);
          });
        } catch (err) {}
      }
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    handleSubmit();
  }, [values]);
  return (
    <div className="tr-report-content-wrapper">
      {trReportsDDLoading || trReportsLoading ? <Loading /> : null}
      <div className="tr-report-header">
        <h2>TR Report</h2>
      </div>
      <div className="report-filter-wrapper">
        <Form onSubmit={handleSubmit}>
          <div className="report-filter-item-wrapper">
            <div className="filter-item-box">
              <Form.Label className="form-label"># of Records</Form.Label>
              <Form.Control
                type="Number"
                min={1}
                className="form-control"
                placeholder="No. of Records"
                id="rowCount"
                value={values.rowCount}
                onChange={(option: any) => {
                  handleChange("rowCount")(option.target.value);
                }}
                isInvalid={touched.rowCount && !!errors.rowCount}
              />
              {touched.rowCount && errors.rowCount ? (
                <Form.Control.Feedback type="invalid">
                  {errors.rowCount}
                </Form.Control.Feedback>
              ) : null}
            </div>
            <div className="filter-item-box type-filter-item">
              <Form.Label className="form-label">TR Type</Form.Label>
              <Select
                classNamePrefix="react-select"
                className="react-select-container"
                options={trTypeOptions}
                placeholder="TR Type"
                id="type"
                name="type"
                value={trTypeOptions?.find(
                  (option: DropDownValues) => option.value === values.type,
                )}
                onChange={(option: any) => {
                  handleChange("type")(option.value);
                }}
              />
            </div>
            <div className="filter-item-box type-filter-item">
              <Form.Label className="form-label">User</Form.Label>
              <Select
                classNamePrefix="react-select"
                className="react-select-container"
                options={userOptions}
                placeholder="User"
                id="userId"
                name="userId"
                value={userOptions?.find(
                  (option: DropDownValues) => option.value === values.userId,
                )}
                onChange={(option: any) => {
                  handleChange("userId")(option.value);
                }}
              />
            </div>
            <div className="filter-item-box">
              <Form.Label className="form-label">Barcode</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Enter Barcode"
                id="barcode"
                value={values.barcode}
                onChange={(option: any) => {
                  handleChange("barcode")(option.target.value);
                }}
              />
            </div>
            <div className="filter-item-box">
              <Form.Label className="form-label">From Date</Form.Label>
              <DatePicker
                format="MM/dd/yyyy"
                openCalendarOnFocus={false}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                clearIcon={null}
                calendarIcon={<i className="bi bi-calendar"></i>}
                showLeadingZeros={true}
                value={values.fromDate}
                maxDate={values.toDate ? new Date(values.toDate) : new Date()}
                onChange={(date: any) => {
                  handleChange({
                    target: {
                      name: "fromDate",
                      value: moment(date).format("YYYY-MM-DD"),
                    },
                  });
                }}
                className={`${
                  touched.fromDate && !!errors.fromDate
                    ? "is-invalid-border"
                    : ""
                } form-control`}
              />
              {touched.fromDate && errors.fromDate && (
                <Form.Control.Feedback type="invalid">
                  {errors.fromDate}
                </Form.Control.Feedback>
              )}
            </div>
            <div className="filter-item-box">
              <Form.Label className="form-label">To Date</Form.Label>
              <DatePicker
                minDate={
                  values.fromDate ? new Date(values.fromDate) : new Date()
                }
                maxDate={new Date()}
                format="MM/dd/yyyy"
                openCalendarOnFocus={false}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                showLeadingZeros={true}
                value={values.toDate}
                clearIcon={null}
                calendarIcon={<i className="bi bi-calendar"></i>}
                onChange={(date: any) => {
                  handleChange({
                    target: {
                      name: "toDate",
                      value: moment(date).format("YYYY-MM-DD"),
                    },
                  });
                }}
                className={`${
                  touched.toDate && !!errors.toDate ? "is-invalid-border" : ""
                } form-control`}
              />
              {touched.toDate && errors.toDate && (
                <Form.Control.Feedback type="invalid">
                  {errors.toDate}
                </Form.Control.Feedback>
              )}
            </div>
            <div className="filter-item-box clear-all-box">
              <button
                type="button"
                className="clear-filter-btn"
                onClick={() => setFormInitialValues(DynamicInitialValues)}
              >
                Clear All
              </button>
            </div>
          </div>
        </Form>
      </div>
      <div className="filter-detail-wrapper">
        <div className="filter-item-detail-wrapper">
          <div className="filter-item-detail-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>TR Date</th>
                  <th>User ID</th>
                  <th>TR Type</th>
                  <th>Ref1</th>
                  <th>Ref2</th>
                  <th>Ref3</th>
                  <th>Details</th>
                  <th>IP Address</th>
                </tr>
              </thead>
              <tbody>
                {trList.map((data) => (
                  <tr>
                    <td>
                      {moment(data.trDateTime).format("MM/DD/YYYY HH:mm:ss")}
                    </td>
                    <td>{data.user.userName}</td>
                    <td>{data.trType}</td>
                    <td>{data.ref1}</td>
                    <td>{data.ref2}</td>
                    <td>{data.ref3}</td>
                    <td>{data.details}</td>
                    <td>{data.sessionRefNo}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      {showModal && (
        <CustomModal
          id="param_detail_modal"
          title="Params Details"
          content={
            <>
              <div className="modal--content--wrapper">
                <div className="param-content-box">
                  <h4>Request</h4>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={`  {"ConfigStr":"SOURCEFUSE|2.4 XD|AllowWS","PatID":"7508","PatFName":"***","PatMI":"*","PatLName":"*************","PatDOB":"********************","PatGender":"****","UserID":"cbulkley","UserFName":"Chris","UserMI":"N","UserLName":"Bulkley-Jones","Username":null,"UserNum":"NA","HCPID":"cbulkley","HCPFName":"Chris","HCPMI":"N","HCPLName":"Bulkley-Jones,NA","HCPName":null,"HCPNum":"","ServerDate":"5/18/2024","ServerTime":"7:38 AM","WkStationID":"NA","WkStationIP":"NA","Location":"WHSE","Barcode":"RX20230202121822262","Qty":"0.50","Units":"mL","Query":"True","UniqueID":"4ffc2e4f-8903-45ad-a825-5d67db061792","Reason":null,"Inventory":null,"ExpiringInventory":null,"EmrOrderId":null,"Name":null,"EncounterId":null,"EncounterDate":null,"EmrOrderDate":null}"`}
                  />
                </div>
                <div className="param-content-box">
                  <h4>Result</h4>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={`{"Status":"F","Msg":"Barcode not found!","Manufacturer":"","RxCategory":"","RxName":"","NDC":"","NDC11":"","LotNo":"","ExpDate":"","CVX":"","UOM":"","Text1":"","Text2":"","Text3":"","List1":"","List2":"","BCScan":"RX20230202121822262","ItemWarning":null,"Inventory":[],"UseUnits":[],"SRXExpiringInventoryList":[]}`}
                  />
                </div>
              </div>
            </>
          }
          onClose={toggleModal}
        />
      )}
    </div>
  );
};

export default TRReport;
