import { Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./inventoryprofile.scss";
import InventoryProfileHeader from "../../../components/inventoryprofileheader/InventoryProfileHeader";
import { useAppSelector } from "../../../redux/hooks";
import {
  Barcode,
  selectedInventoryItem,
} from "../../../redux/inventory/inventorySlice";
import { BasicInfoTab } from "./basicInfoTab/basicInfoTab";
import { InventoryManagementTab } from "./inventoryManagementTab/inventoryManagementTab";
import { useNavigate } from "react-router-dom";
import { InventoryTransactionReportsModel } from "./inventoryManagementTab/popUpModels/inventoryTransactionReportModel";
import { OrderManagementTab } from "./OrderManagementTab/OrderManagementTab";
import Loading from "../../../components/LoadingPage/Loading";
import { DuplicateItemModel } from "../Itemlisting/popUpModels/duplicateItemModel";
import { toggleClass } from "../../../utils/commonUtils";
import { OrderManagementDetails } from "./OrderManagementTab/popUp/OrderManagementDetails";
import { BarcodeManagementTab } from "./barcodeManagementTab/barcodeManagementTab";
import useInventory from "../../../Hooks/useInventory";
import { PrintBarcodePopUp } from "./printBarcodePopUp/printBarcodePopUp";
import React from "react";
import { toast } from "react-toastify";
const InventoryProfile = () => {
  const selectedItem = useAppSelector(selectedInventoryItem);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [invManageData, setInvManageData] = useState<any>([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const onCloseReportModal = () => setShowReportModal(false);
  const onShowReportModal = () => setShowReportModal(true);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [selectedOrdManItem, setSelectedOrdManItem] = useState<any>();
  const [showBarcodeModal, setShowBarcodeModal] = useState(false);
  const [defaultBarcodes, setDefaultBarcodes] = useState<Barcode>();
  const { getBarcodesAPI, getBarcodesLoading, barcodePrintAPI } = useInventory();
  const [showOrderMngtDetailModal, setShowOrderMngtDetailModal] =
    useState(false);
  const getBarcodeList = () => {
    getBarcodesAPI(selectedItem.id).then((res: any) => {
      if (res) {
        setDefaultBarcodes(res.find((x: any) => x.isDefault));
      }
    });
  };
  useEffect(() => {
    getBarcodeList();
  }, []);
  useEffect(() => {
    if (!selectedItem) {
      navigate("/inventory");
    }
    setLoading(false);
  }, [loading]);

  const toggleOrderMngtDetailModal = () => {
    toggleClass();
    setShowOrderMngtDetailModal(!showOrderMngtDetailModal);
  };

  const toggleDuplicateModal = () => {
    toggleClass();
    setShowDuplicateModal(!showDuplicateModal);
  };
  const toggleBarcodeModal = () => {
    toggleClass();
    setShowBarcodeModal(!showBarcodeModal);
  };

  const reloadListing = () => {
    navigate("/inventory");
  };
  const selectOrdManItem = (item: any) => {
    setSelectedOrdManItem(item);
  };
  const printBarcode = (label: string, barcode: string) => {
    barcodePrintAPI({
      lableId: label,
      id: barcode,
    }).then((res: any) => {
      window.open(res.data.result, "_blank", "noopener,noreferrer");
    });
  };
  const showRequiredOderProdError = (tab: string | null) => {
    if (!!!selectedItem?.orderingProductMasterId && tab === "ordmngt") {
      toast.error("Please select Ordering Product for this Item", {
        autoClose: 3000,
      });
    }
  };
  const refreshOMTab = (data: any) => {
    setInvManageData(data);
  };
  return (
    <>
      {loading || getBarcodesLoading ? (
        <Loading />
      ) : (
        <div className="inventory-profile-wrapper">
          <InventoryProfileHeader
            status={selectedItem.isActive}
            itemName={selectedItem.rxName}
          />
          <div className="profile-detail-box-wrapper">
            <div className="inventory-profile-detail-box">
              <Tabs
                defaultActiveKey="overview"
                id="inventory-profile-tab"
                className="mb-3"
                onSelect={showRequiredOderProdError}
              >
                <Tab eventKey="overview" title="Overview">
                  <BasicInfoTab
                    toggleBarcodeModal={toggleBarcodeModal}
                    toggleDuplicateModal={toggleDuplicateModal}
                    selectedItem={selectedItem}
                  />
                </Tab>
                <Tab eventKey="invtmngt" title="Inventory Management">
                  <InventoryManagementTab
                    onShowReportModal={onShowReportModal}
                    selectedItem={selectedItem}
                    refreshOMTab={refreshOMTab}
                  />
                </Tab>

                <Tab eventKey="ordmngt" title="Order Management">
                  <OrderManagementTab
                    toggleOrderMngtDetailModal={toggleOrderMngtDetailModal}
                    selectedItem={selectedItem}
                    setSelectedOrdManItem={selectOrdManItem}
                    invManageData={invManageData}
                  />
                </Tab>

                <Tab eventKey="barcodes" title="Barcodes">
                  <BarcodeManagementTab
                    selectedItem={selectedItem}
                    printBarcode={printBarcode}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>

          {/* Duplicate Item Modal */}
          <DuplicateItemModel
            selectedItem={selectedItem}
            toggleDuplicateModal={toggleDuplicateModal}
            showModal={showDuplicateModal}
            reloadListing={reloadListing}
          />

          {/* Barcode Modal */}
          <PrintBarcodePopUp
            showBarcodeModal={showBarcodeModal}
            toggleBarcodeModal={toggleBarcodeModal}
            printBarcode={printBarcode}
            defaultBarcodes={defaultBarcodes}
          />

          {/* Order Mngt Detail Modal */}
          <OrderManagementDetails
            showOrderMngtDetailModal={showOrderMngtDetailModal}
            toggleOrderMngtDetailModal={toggleOrderMngtDetailModal}
            selectedOrdManItem={selectedOrdManItem}
            selectedItem={selectedItem}
          />

          {/* Transaction Report Modal */}
          <InventoryTransactionReportsModel
            showReportModal={showReportModal}
            onCloseReportModal={onCloseReportModal}
            selectedItem={selectedItem}
          />
        </div>
      )}
    </>
  );
};

export default InventoryProfile;
