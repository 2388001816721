import CustomModal from "../../../../../components/customModal/CustomModal";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { DropDownValues } from "../../../../../utils/types";
import DatePicker from "react-date-picker";
import { useEffect, useState } from "react";

export const InventoryCountModel = ({
  locationDropDownVal,
  selectedItem,
  selectCurrentUserId,
  toggleInventoryCountModal,
  inventoryTransactionsList,
  invTransCount,
  inventoryCountList,
  activeLocation,
}: any) => {
  const [systemCount, setSystemCount] = useState();
  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      finalQuantity: "",
      locationId: activeLocation ?? "",
      transactionId: inventoryTransactionsList?.find(
        (option: DropDownValues) => option.label.toLowerCase() === "count",
      )?.value,
      note: "",
      verifyDate: new Date(),
      itemId: selectedItem.id,
      userId: selectCurrentUserId,
    },
    validationSchema: Yup.object({
      locationId: Yup.string().required("Location is Required"),
      verifyDate: Yup.string().required("Date Type is Required"),
      finalQuantity: Yup.number()
        .min(0, "Physical Count must be a positive number")
        .required("Physical Count is Required"),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        invTransCount(values);
      } catch (err: any) {}
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (activeLocation) {
      locationChange(activeLocation);
    }
  }, [activeLocation]);

  const locationChange = (location: string) => {
    setSystemCount(
      inventoryCountList.find((x: any) => x.locationId === location).quantity,
    );
  };
  return (
    <CustomModal
      title="Count Inventory"
      content={
        <div className="modal--content--wrapper">
          <div className="modal--inputbox">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Select Location</Form.Label>
                <Select
                  placeholder="Select Location"
                  classNamePrefix="react-select"
                  options={locationDropDownVal}
                  id="locationId"
                  name="locationId"
                  value={locationDropDownVal?.find(
                    (option: any) => option.value === values.locationId,
                  )}
                  onChange={(option: any) => {
                    locationChange(option.value);
                    handleChange("locationId")(option.value);
                  }}
                  className={`${
                    touched.locationId && !!errors.locationId
                      ? "is-invalid-border"
                      : ""
                  } react-select-container `}
                />
                {touched.locationId && errors.locationId && (
                  <Form.Control.Feedback type="invalid">
                    {errors.locationId as string}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Verified Date</Form.Label>
                <div className="calendar-box">
                  <DatePicker
                    format="MM/dd/yyyy"
                    openCalendarOnFocus={false}
                    className="form-control"
                    value={values.verifyDate}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    showLeadingZeros={true}
                    onChange={(date) =>
                      handleChange({
                        target: { name: "verifyDate", value: date },
                      })
                    }
                    clearIcon={null}
                    calendarIcon={<i className="bi bi-calendar"></i>}
                  />
                </div>
              </Form.Group>
              <div className="profile-info-box mb-3">
                <label>System Count</label>
                <p className="disable-label-field">{systemCount ?? "-"}</p>
              </div>

              <Form.Group className="mb-3">
                <Form.Label>Physical Count</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Physical Count"
                  isInvalid={touched.finalQuantity && !!errors.finalQuantity}
                  id="finalQuantity"
                  name="finalQuantity"
                  onChange={handleChange}
                  value={values.finalQuantity}
                />
                {touched.finalQuantity && errors.finalQuantity ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.finalQuantity}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Reference Info</Form.Label>
                <Form.Control
                  type="text"
                  id="note"
                  name="note"
                  placeholder="Enter Reference Info"
                  isInvalid={touched.note && !!errors.note}
                  onChange={handleChange}
                  value={values.note}
                />
                {touched.note && errors.note ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.note}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="full-width text-end">
                <Button
                  onClick={toggleInventoryCountModal}
                  style={{ marginRight: "10px" }}
                  variant="outline-primary"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Count
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      }
      onClose={toggleInventoryCountModal}
    />
  );
};
