import Form from "react-bootstrap/Form";
import "./addsetting.scss";
import { Button } from "react-bootstrap";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import useSettings from "../../../Hooks/useSettings";
import Loading from "../../../components/LoadingPage/Loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
const AddSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const {
    createSettingsLoading,
    createSettingsAPI,
    getSettingsByIdAPI,
    getSettingsByIdLoading,
    editSettingsAPI,
    editSettingsLoading,
  } = useSettings();
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();
  const [dynamicVal, setDynamicVal] = useState({
    isActive: true,
    createdBy: currentUserId,
    settingName: "",
    settingValue: "",
    category: "",
    notes: "",
  });
  useEffect(() => {
    if (location.pathname !== "/add-setting") {
      setIsEdit(true);
      if (id) {
        getSettingsByIdAPI(id)
          .then((data: any) => {
            setDynamicVal({
              isActive: data.isActive,
              createdBy: data.createdBy,
              settingName: data.settingName,
              settingValue: data.settingValue,
              category: data.category,
              notes: data.notes,
            });
          })
          .catch();
      } else {
        navigate("/setting");
      }
    }
  }, []);
  const { handleChange, values, handleSubmit, touched, errors,setFieldValue } = useFormik({
    initialValues: dynamicVal,
    validationSchema: Yup.object({
      settingName: Yup.string()
        .required("Setting Name is Required")
        .max(50, "Setting Name can not be more than 50 characters"),
      settingValue: Yup.string().required("Setting Value is Required"),
      category: Yup.string().max(
        50,
        "Category can not be more than 50 characters",
      ),
      notes: Yup.string(),
    }),

    onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
      try {
        if (isEdit) {
          await editSettingsAPI({ ...values, id: id });
        } else {
          await createSettingsAPI(values);
        }
        navigate("/settings");
      } catch (err: any) {}
      setSubmitting(false);
    },
    enableReinitialize: true,
  });
  return (
    <div className="add-setting-wrapper">
      {createSettingsLoading ||
      getSettingsByIdLoading ||
      editSettingsLoading ? (
        <Loading />
      ) : null}
      <div className="page-title">
        <h2>{isEdit ? "Edit" : "Add"} Setting</h2>
      </div>
      <div className="add-setting-box">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>Setting Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Setting Name"
              id="settingName"
              onChange={(e) =>
                handleChange("settingName")(e.target.value ?? "")
              }
              value={values.settingName}
              isInvalid={touched.settingName && !!errors.settingName}
            />
            {touched.settingName && errors.settingName ? (
              <Form.Control.Feedback type="invalid">
                {errors.settingName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Setting Value</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Setting Value"
              id="settingValue"
              onChange={(e) =>
                handleChange("settingValue")(e.target.value ?? "")
              }
              value={values.settingValue}
              isInvalid={touched.settingValue && !!errors.settingValue}
            />
            {touched.settingValue && errors.settingValue ? (
              <Form.Control.Feedback type="invalid">
                {errors.settingValue}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Category"
              id="category"
              onChange={(e) => handleChange("category")(e.target.value ?? "")}
              value={values.category}
              isInvalid={touched.category && !!errors.category}
            />
            {touched.category && errors.category ? (
              <Form.Control.Feedback type="invalid">
                {errors.category}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4 full-width">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="notes"
              id="notes"
              onChange={(e) => handleChange("notes")(e.target.value ?? "")}
              value={values.notes}
              isInvalid={touched.notes && !!errors.notes}
            />
            {touched.notes && errors.notes ? (
              <Form.Control.Feedback type="invalid">
                {errors.notes}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Check
              type="switch"
              id="settingStatus"
              label="Active"
              name="settingStatus"
               isInvalid={touched.isActive && !!errors.isActive}
              checked={values.isActive}
              onChange={(e) => setFieldValue('isActive', e.target.checked)}
            />
            {touched.isActive && errors.isActive ? (
              <Form.Control.Feedback type="invalid">
                {errors.isActive}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="mb-4 full-width text-end">
            <Button
              style={{ marginRight: "10px" }}
              variant="outline-primary"
              onClick={() => navigate("/settings")}
            >
              Cancel
            </Button>
            <Button type="submit">{isEdit ? "Update" : "Add"} </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default AddSetting;
