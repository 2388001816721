import ReactDOM from "react-dom";
import "./custommodal.scss";
interface ModalProps {
  title: string;
  id?: string;
  content: React.ReactNode;
  footer?: React.ReactNode;
  onClose?: () => void;
}
const CustomModal: React.FC<ModalProps> = ({
  title,
  id,
  content,
  footer,
  onClose,
}) => {
  return ReactDOM.createPortal(
    <div className="modal--overlay">
      <div id={id} className="modal--content">
        <div
          className="modal--header"
          style={{ borderBottom: title === "" ? "none" : "1px solid #F2F2F2" }}
        >
          <h4>{title}</h4>
          <span className="close--modal" onClick={onClose}>
            <i className="bi bi-x-lg"></i>
          </span>
        </div>
        <div className="modal--body">{content}</div>
        {footer && <div className="modal--footer">{footer}</div>}
      </div>
    </div>,
    document.getElementById("modal-root")!,
  );
};

export default CustomModal;
