import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";
const stockOptions = [
  { label: "Private", value: "private" },
  { label: "Federal", value: "federal" },
  { label: "Petients", value: "petients" },
];

const locationOptions = [
  {
    label: "1234 Maple Street Springfield, Anytown, USA",
    value: "1234 Maple Street Springfield, Anytown, USA",
  },
  {
    label: "5678 Oak Avenue Riverside, Smalltown, USA",
    value: "5678 Oak Avenue Riverside, Smalltown, USA",
  },
  {
    label: "910 Elm Street Lakeside, Anytown, USA",
    value: "910 Elm Street Lakeside, Anytown, USA",
  },
  {
    label: "246 Pine Drive Sunnyvale, Suburbia, USA",
    value: "246 Pine Drive Sunnyvale, Suburbia, USA",
  },
  {
    label: "789 Washington Boulevard Hillcrest, Middle America, USA",
    value: "789 Washington Boulevard Hillcrest, Middle America, USA",
  },
  {
    label: "910 Elm Street Lakeside, Anytown, USA",
    value: "910 Elm Street Lakeside, Anytown, USA",
  },
  {
    label: "246 Pine Drive Sunnyvale, Suburbia, USA",
    value: "246 Pine Drive Sunnyvale, Suburbia, USA",
  },
];
const typeOptions = [
  { label: "Pediatric Vaccine (PDV)", value: "Pediatric Vaccine" },
  { label: "Vaccine (VAC)", value: "Vaccine" },
  { label: "Procedure (PROC)", value: "Procedure" },
  { label: "Injection (INJEC)", value: "Injection" },
  { label: "IT Equipment 1 (ITEQUIP)", value: "IT Equipment 1 (ITEQUIP)" },
  { label: "Covid 19 Test (COVID 19)", value: "Covid 19 Test (COVID 19)" },
];

const overrideStrings = {
  search: "Search",
  selectAll: "All",
  selectSomeItems: "All",
};
const RecalledInventoryReport = () => {
  const [selectedStock, setSelectedStock] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedLoc, setSelectedLoc] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [isIncludeZero, setIsIncludeZero] = useState(true);
  const [reportKey, setReportKey] = useState(0);
  const cleearAll = () => {
    setIsIncludeZero(true);
    setIsActive(true);
  };
  return (
    <>
      <div className="report-filter-wrapper" style={{ marginBottom: "20px" }}>
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box location-filter-item">
            <label className="form-label">Location</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={locationOptions}
              value={selectedLoc}
              onChange={setSelectedLoc}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Type</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={typeOptions}
              value={selectedStock}
              onChange={setSelectedStock}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Stock</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={stockOptions}
              value={selectedType}
              onChange={setSelectedType}
              labelledBy="Select"
              disableSearch={true}
            />
          </div>
          <div className="filter-item-box filter-checkbox-items">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={(e) => {
                  setIsActive(e.target.checked);
                  setReportKey((prevKey) => prevKey + 1);
                }}
              />
              <label className="form-check-label">Active</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={(e) => {
                  setIsIncludeZero(e.target.checked);
                  setReportKey((prevKey) => prevKey + 1);
                }}
              />
              <label className="form-check-label">Include 0 Quantity</label>
            </div>
          </div>
          <div className="filter-item-box clear-all-box">
            <button
              type="button"
              className="clear-filter-btn"
              onClick={cleearAll}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
      <TelerikReporting
        key={reportKey}
        params={{
          locationId: "",
          rxTypeId: "",
          stockId: "",
          isActive: isActive,
          isZeroQty: isIncludeZero,
          isExport: "true",
        }}
        reportName="RecalledItemInventoryReport.trdp"
        reportEndPoint="reports/inventoryrecalleddetails/get"
      />
    </>
  );
};

export default RecalledInventoryReport;
